import React from "react";
import RecipeList from "../components/RecipeList";
import "bootstrap/dist/css/bootstrap.min.css";
import "./HomePage.css";

const HomePage = () => {
  return (
    <>
      <section className="bg-light py-5 text-center header-animation">
        <div className="container">
          <h1 className="display-4 fw-bold text-dark">
            Descubre Sabores Únicos
          </h1>
          <p className="lead text-secondary">
            Encuentra las mejores recetas para cada ocasión. Desde platos
            sencillos hasta elaborados menús gourmet, ¡todo a tu alcance!
          </p>
        </div>
      </section>
      <div className="recipe-list-animation">
        <RecipeList />
      </div>
    </>
  );
};

export default HomePage;
