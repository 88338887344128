import React, { useState } from "react";
import "./LoginForm.css";

const RegisterForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  //   const [showError, setShowError] = useState(false);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const newUserData = {
      username,
      email,
      password,
    };

    try {
      const response = await fetch(
        "https://recetas-cocina-back.vercel.app/api/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newUserData),
        }
      );

      if (response.ok) {
        console.log("Hecho");
        setUsername("");
        setEmail("");
        setPassword("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="form-container">
        <h2 className="mb-4 text-center">Formulario de Registro</h2>
        <form onSubmit={handleLoginSubmit}>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">
              Nombre de usuario
            </label>
            <input
              value={username}
              type="text"
              className="form-control"
              id="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Correo Electrónico
            </label>
            <input
              value={email}
              type="email"
              className="form-control"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Contraseña
            </label>
            <input
              value={password}
              type="password"
              className="form-control"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button type="submit" className="btn btn-dark w-100">
            Registro
          </button>
        </form>

        {/* {showError && (
          <div className="alert alert-danger mt-3" role="alert">
            El nombre de usuario o la contraseña no existen
          </div>
        )} */}
      </div>
    </div>
  );
};

export default RegisterForm;
