import React, { useState } from "react";
import "./LoginForm.css";
import { useNavigate } from "react-router-dom";

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const handleLoginSubmit = (e) => {
    e.preventDefault();

    if (email === "" || password === "") {
      setShowError(true);
      return;
    }

    fetch(
      `https://recetas-cocina-back.vercel.app/api/login/${email}/${password}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length === 0) {
          setShowError(true);
        } else {
          setShowError(false);
          onLogin(data[0].id);
          navigate("/");
        }
      })
      .catch(() => {
        setShowError(true);
      });
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="form-container">
        <h2 className="mb-4 text-center">Formulario de Registro</h2>
        <form onSubmit={handleLoginSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Correo Electrónico
            </label>
            <input
              value={email}
              type="email"
              className="form-control"
              id="email"
              placeholder="name@example.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Contraseña
            </label>
            <input
              value={password}
              type="password"
              className="form-control"
              id="password"
              placeholder="Contraseña"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <p>Cuenta de Prueba:</p>
            <p>Email: pablo@gmail.com - Contraseña: 123456</p>
          </div>

          <button type="submit" className="btn btn-dark w-100">
            Login
          </button>
        </form>

        {showError && (
          <div className="alert alert-danger mt-3" role="alert">
            El nombre de usuario o la contraseña no existen
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
