import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Importa Link para la navegación interna
import "./RecipeList.css";

const RecipeList = () => {
  const [recetas, setRecetas] = useState([]);

  const fetch_fecha = () => {
    fetch("https://recetas-cocina-back.vercel.app/api/recetas_fecha") // Cambia este endpoint si es necesario
      .then((res) => res.json())
      .then((data) => {
        setRecetas(data);
      });
  };

  const fetch_rating = () => {
    fetch("https://recetas-cocina-back.vercel.app/api/recetas_rating") // Asegúrate de que este sea el endpoint correcto
      .then((res) => res.json())
      .then((data) => {
        setRecetas(data);
      });
  };

  useEffect(() => {
    fetch_fecha(); // Por defecto, ordenar por fecha al cargar la página
  }, []);

  return (
    <div className="container">
      <div className="Orden d-flex mb-3 align-items-center">
        <label>Ordenar por:</label>
        <button className="btn btn-dark mx-2" onClick={fetch_fecha}>
          Fecha
        </button>
        <button className="btn btn-dark" onClick={fetch_rating}>
          Puntuación
        </button>
      </div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
        {recetas.map((receta) => {
          return (
            <div className="col" key={receta.id}>
              <Link
                to={`/recipe-page/${receta.id}`}
                className="text-decoration-none text-dark"
              >
                <div className="card h-100 recipe-card">
                  <img
                    src={`/assets/${receta.image}`}
                    className="card-img-top recipe-card-img"
                    alt={receta.title}
                  />
                  <div className="card-body">
                    <h5 className="card-title recipe-card-title">
                      {receta.title}
                    </h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      Autor: {receta.author_name}
                    </h6>
                    <div className="rating">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <span key={value} className="star-container">
                          <span
                            className={`star ${
                              value <= Math.floor(receta.average_rating)
                                ? "selected"
                                : ""
                            }`}
                          >
                            &#9733;
                          </span>
                          {value === Math.floor(receta.average_rating) + 1 &&
                            receta.average_rating % 1 > 0 && (
                              <span
                                className="star-overlay"
                                style={{
                                  width: `${
                                    (receta.average_rating % 1) * 100
                                  }%`,
                                }}
                              >
                                &#9733;
                              </span>
                            )}
                        </span>
                      ))}
                    </div>
                    <p className="card-text recipe-card-text">
                      {receta.description}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecipeList;
