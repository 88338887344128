import React, { useEffect, useState } from "react";
import CommentForm from "../components/CommentForm";
import { useParams } from "react-router-dom";
import RecipeDetail from "../components/RecipeDetail";

import "./RecipePage.css";

const RecipePage = () => {
  const { id } = useParams();

  const [recipeData, setRecipeData] = useState(null);

  useEffect(() => {
    fetch(`https://recetas-cocina-back.vercel.app/api/recetas/${id}`)
      .then((res) => res.json())
      .then((data) => setRecipeData(data));
  }, [id]);

  return (
    <div className="detalle_receta">
      <RecipeDetail recipe={recipeData} />
      <CommentForm param={id} />
    </div>
  );
};

export default RecipePage;
