import React, { useEffect, useState } from "react";
import "./Rating.css";

const Rating = ({ info_rating }) => {
  const [fullRating, setFullRating] = useState(info_rating);
  const [fullInfoRating, setFullInfoRating] = useState([]);

  useEffect(() => {
    setFullRating(info_rating);
    fetch(`https://recetas-cocina-back.vercel.app/api/rating/${info_rating.id}`)
      .then((res) => res.json())
      .then((data) => setFullInfoRating(data[0]));
  }, [info_rating]);

  return (
    <div className="comentario card mb-3">
      {fullInfoRating ? (
        <div className="card-body">
          <div className="d-flex justify-content-between mb-2">
            <h5 className="card-title">{fullInfoRating.username}</h5>
            <h6 className="card-subtitle text-muted">
              {fullInfoRating.created_at
                ? fullInfoRating.created_at.substring(0, 10)
                : ""}
            </h6>
          </div>
          <p className="card-text">{fullInfoRating.comment}</p>
          <div className="rating">
            {[1, 2, 3, 4, 5].map((value) => (
              <span
                key={value}
                className={`star-form ${
                  value <= fullInfoRating.rating ? "selected" : ""
                }`}
              >
                &#9733;
              </span>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-center">Cargando...</div>
      )}
    </div>
  );
};

export default Rating;
