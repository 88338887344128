import React, { useState, useEffect } from "react";
import "./CommentForm.css";
import Rating from "./Rating";

const CommentForm = ({ param }) => {
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [ratings, setRatings] = useState([]);
  const user_id = localStorage.getItem("user");

  useEffect(() => {
    fetch(`https://recetas-cocina-back.vercel.app/api/ratings/${param}`)
      .then((res) => res.json())
      .then((data) => {
        setRatings(data);
      })
      .catch((error) => console.error("Error fetching ratings:", error));
  }, [param]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newCommentData = {
      user_id: user_id,
      recipe_id: param,
      rating,
      comment,
    };

    try {
      const response = await fetch(
        "https://recetas-cocina-back.vercel.app/api/rate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newCommentData),
        }
      );

      if (response.ok) {
        fetch(`https://recetas-cocina-back.vercel.app/api/ratings/${param}`)
          .then((res) => res.json())
          .then((data) => {
            setRatings(data);
            setComment("");
            setRating(0);
          })
          .catch((error) => console.error("Error fetching ratings:", error));
        if (rating === 0) {
          alert("Añade una puntuación a la receta para comentar");
        }
      } else {
        throw new Error(response.status);
      }
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  const handleMouseOver = (value) => {
    setHover(value);
  };

  const handleMouseOut = () => {
    setHover(0);
  };

  const handleRatingClick = (value) => {
    setRating(value);
  };

  return (
    <div className="container mt-4">
      {user_id ? (
        <form id="comment_form" onSubmit={handleSubmit}>
          <h3>Añade un comentario</h3>
          <div className="row mb-3">
            <div className="col-md-8">
              <textarea
                className="form-control"
                id="comment"
                rows="3"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
                placeholder="Escribe tu comentario"
              />
            </div>
            <div className="col-md-4 d-flex flex-column align-items-start">
              <h3>Puntua la receta</h3>
              <div className="stars">
                {[1, 2, 3, 4, 5].map((value) => (
                  <span
                    key={value}
                    className={`star-form ${
                      value <= (hover || rating) ? "selected" : ""
                    }`}
                    onClick={() => handleRatingClick(value)}
                    onMouseOver={() => handleMouseOver(value)}
                    onMouseOut={handleMouseOut}
                  >
                    &#9733;
                  </span>
                ))}
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-dark mt-3">
            Comentar
          </button>
        </form>
      ) : (
        <h3>Inicia sesión para añadir un comentario</h3>
      )}

      <div className="seccion_comentarios mt-4">
        <h1>Comentarios</h1>
        {ratings.length > 0 ? (
          ratings.map((rating) => (
            <div key={rating.id} className="comment-animation">
              <Rating info_rating={rating} />
            </div>
          ))
        ) : (
          <h3>No hay comentarios</h3>
        )}
      </div>
    </div>
  );
};

export default CommentForm;
