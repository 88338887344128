import React, { useEffect, useState } from "react";
import "./RecipeDetail.css";

const RecipeDetail = ({ recipe }) => {
  const [datosReceta, setDatosReceta] = useState([]);
  const [score, setScore] = useState(0);
  const [avgRating, setAvgRating] = useState("");

  useEffect(() => {
    if (recipe && recipe.length > 0) {
      setDatosReceta(recipe);
      fetch(
        `https://recetas-cocina-back.vercel.app/api/ratings/${recipe[0].id}`
      )
        .then((res) => res.json())
        .then((datos) => {
          if (datos && datos.length > 0) {
            const total = datos.reduce(
              (suma, rating) => suma + rating.rating,
              0
            );
            const promedio = total / datos.length;
            setAvgRating(promedio);
            setScore(promedio);
          }
        });
    }
  }, [recipe]);

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6">
          <div className="contenedor_imagen">
            {datosReceta.length > 0 ? (
              <img
                src={`/assets/${datosReceta[0].image}`}
                alt="Recipe"
                className="img-fluid"
              />
            ) : (
              <h2>Cargando...</h2>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="info_principal">
            {datosReceta.length > 0 && (
              <>
                <h3>{datosReceta[0].title}</h3>
                <div className="rating">
                  {[1, 2, 3, 4, 5].map((value) => (
                    <span key={value} className="star-container">
                      <span
                        className={`star ${
                          value <= Math.floor(score) ? "selected" : ""
                        }`}
                      >
                        &#9733;
                      </span>
                      {value === Math.floor(score) + 1 && score % 1 > 0 && (
                        <span
                          className="star-overlay"
                          style={{ width: `${(score % 1) * 100}%` }}
                        >
                          &#9733;
                        </span>
                      )}
                    </span>
                  ))}
                  <span>{avgRating ? avgRating.toFixed(1) : null}</span>
                </div>
                <p>{datosReceta[0].description}</p>
                <h3>Ingredientes:</h3>
                <p>{datosReceta[0].ingredients}</p>
                <h3>Pasos:</h3>
                <p>
                  {datosReceta[0].steps.split("\n").map((linea, index) => {
                    return (
                      <span key={index}>
                        {linea}
                        <br />
                      </span>
                    );
                  })}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeDetail;
